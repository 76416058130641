<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="changeStatusMagModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Zmień status magazynowy wybranych wierszy</h5>

            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ error }}
            </div>

            <div class="col-md-12">
              <label for="project-select">Wybierz status:</label>
              <select id="project-select" class="form-select form-select-sm" @change="updateSelectedStatus"
                      v-model="newSelectedStatus">
                <option v-for="status in statuses" :value="status.name"> {{ status.name }}</option>
              </select>
            </div>

            <div class="table-responsive mt-5">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>Nr poz.</th>
                  <th> Podzespół nr rysunku</th>
                  <th>Nr katalogowy</th>
                  <th>Model</th>
                  <th>Nazwa/Opis</th>
                  <th>Ilość wymagana</th>
                  <th>Ilość magazynowa</th>
                  <th>Status</th>
                  <th>Status Mag</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="record in selectedRecords">
                  <td>{{ record.id }}</td>
                  <td>{{ record.subteam_drawing_no }}</td>
                  <td>{{ record.article?.catalog_number || '' }}</td>
                  <td>{{ record.article.model }}</td>
                  <td>{{ record.article.description }}</td>
                  <td>{{ record.quantity_required }}</td>
                  <td>{{ record.article.stock_quantity }}</td>
                  <td>{{ record.status }}</td>
                  <td>{{ record.inventory_status }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>

            <form @submit.prevent="submitForm">
              <button type="submit" class="btn btn-primary">
                <i class="bi bi-save me-2"></i>Zapisz
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {Modal} from "bootstrap";

let dropInstanceFilterUnits, delivererElem, multiSelectObj;

export default {
  props: {
    selectedRecords: Object,
    statuses: Object
  },

  data() {

    return {
      newSelectedStatus: null,
      error: null,
    }
  },

  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
  },

  methods: {
    updateSelectedStatus(event) {
      this.newSelectedStatus = event.target.value;
    },

    submitForm() {
      axios.post(route('requiredProjectItems.change-status-mag', {
            records: this.selectedRecords,
            newStatus: this.newSelectedStatus
          }
      )).then(() => {
        this.$emit('added-success', 'Zmieniono status poprawnie');
        this.$emit('updated-article');
        this.hide();
      }).catch(error => {
        this.error = error.response.data
        console.log('error', error);
      });
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}

</script>

<style scoped>

</style>