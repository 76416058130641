<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addQuoteRequestModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ruch magazynowy</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="project && project !== '0' && team && team !== 'null'">

            <small v-if="checkWarehouses && !this.selectedItems?.quote_request_item_id" class="text-warning">
              Nie można wybrać innego magazynu niż główny, ponieważ zamówienie nie pochodzi z zapotrzebowania
              projektowego.
            </small>
            <p class="text-primary">
              <i>Magazyn oraz nr dokumentu obliczane są na podstawie pierwszego zaznaczonego elementu.</i>
            </p>
            <label for="selectWarehouse">Wybierz docelowy magazyn</label>
            <select class="form-select form-select-sm" id="selectWarehouse"
                    @change="onWarehouseChange">
              <option selected disabled>-- Wybierz magazyn --</option>
              <option v-for="warehouse in availableWarehouses" :value="warehouse.id">
                {{ warehouse.short_name }}
              </option>
            </select>

            <div v-if="form" class="container-fluid">

              <template v-if="!form.newWarehouse?.id">
                Brak ruchu magazynowego
              </template>

              <template v-else>

                <div v-if="form">
                  <table class="table order-list-table">
                    <tbody>
                    <tr>
                      <td class="widthFirstTd">Numer Dokumentu</td>
                      <td>
                        {{ form.document_number }}
                      </td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Magazyn po zmianie</td>
                      <td>
                        {{ form.newWarehouse.short_name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Komentarz</td>
                      <td>
                        <input type="text" class="form-control form-control-sm"
                               v-model="form.comment">
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="border-bottom: 0">
                        <table class="table mt-4">
                          <thead>
                          <tr>
                            <th>Nr&nbsp;artykułu</th>
                            <th>Model&nbsp;artykułu</th>
                            <th>Z&nbsp;zamówienia</th>
                            <th>Ilość</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(selectedItem, k) in selectedItems" :key="selectedItem.id">
                            <td>{{ selectedItem.article?.catalog_number }}</td>
                            <td>{{ selectedItem.article?.model }}</td>
                            <td>{{ selectedItem.order_list?.order_number }}</td>
                            <td>
                              <template v-if="selectedItem.can_disposition && selectedItem.can_disposition > 0">
                                <div v-if="selectedItem.quantity_disposed === null
                              || selectedItem.quantity_disposed === ''
                              || selectedItem.quantity_disposed < 0"
                                     class="text-danger small">
                                  Ilość musi być większa lub równa 0
                                </div>

                                <input type="number" step="0.1" min="0" :max="selectedItem.can_disposition"
                                       class="form-control form-control-sm" v-model="selectedItem.quantity_disposed"
                                       @change="onChangeQuantityDisposed(k, $event)">
                              </template>
                              <template v-else>
                                Brak wolnej ilości tego artykułu do wysłania.
                              </template>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

              </template>
            </div>
          </div>
          <div v-else class="p-4">
            <span class="text-danger">Nie można wykonać ruchu magazynowego bo nie wybrano projektu i zespołu.</span>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
            <form @submit.prevent="saveMovement" v-show="(form.oldWarehouse?.id !== form.newWarehouse?.id)">
              <button type="submit" class="btn btn-primary"
                      :disabled="form.processing
                      || (form.quantity === null || form.quantity === '' || form.quantity <= 0)">
                <i class="bi bi-save me-2"></i>Zapisz
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal, Tooltip} from "bootstrap";
import {useForm} from "@inertiajs/vue3";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";

export default {
  components: {'ejs-datepicker': DatePickerComponent},
  props: {
    project: Number | String,
    team: String,
    selectedItems: Object,
    checkWarehouses: true
  },

  data() {

    return {
      errors: {},
      firstItem: {},
      warehouses: {},
      availableWarehouses: {},
      canDisposition: 0,
      isInitialChangeFired: false,
      dueDateFormat: 'dd.MM.yyyy',
      form: useForm({})
    }
  },

  watch: {
    selectedItems: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.firstItem = newValue[0];
          this.availableWarehouses = this.warehouses;

          // UWAGA. Sprawdzane tylko dla pierwszego elementu!
          // Sprawdź, czy zamówienie jest z zapotrzebowania, jeśli tak, to pokaż wszystkie magazyny,
          // jeśli nie, to pokaż tylko magazyn główny.
          // Chyba że zmienna checkWarehouses mówi inaczej
          if (!this.firstItem?.quote_request_item_id && this.checkWarehouses) {
            this.availableWarehouses = this.warehouses.filter(warehouse => warehouse.name === 'Główny');
          } else {
            this.availableWarehouses = this.warehouses;
          }

          // szukaj magazynu, który jest zaznaczony w projekcie, żeby automatycznie go zaznaczyć
          const defaultWarehouse = this.availableWarehouses.find(
              warehouse => this.firstItem.project.number.toLowerCase() === warehouse.short_name.toLowerCase()
          );

          // Każdy z zaznaczonych:
          // sprawdź jak dużo można rozdysponować tego artykułu

          this.selectedItems.forEach(item => {
            let availableQuantity = parseFloat(item?.delivered_quantity) || parseFloat(item.article_main_warehouse?.quantity) || 0;
            item.can_disposition = availableQuantity - (item.dispatched_quantity || 0);
            if (item.can_disposition < 0) {
              item.can_disposition = 0;
            } else {
              item.quantity_disposed = item.can_disposition;
            }
          });

          this.$nextTick(() => {
            if (defaultWarehouse) {
              // jeśli znalazł magazyn domyślny, to go wybierz
              this.onWarehouseChange({target: {value: defaultWarehouse.id}});
              this.selectWarehouseOption(defaultWarehouse.id);
            } else {
              // jeśli nie znalazł magazynu domyślnego, to wybierz główny (id: 1)
              this.onWarehouseChange({target: {value: 1}});
              this.selectWarehouseOption(1);
            }
          })
        }
      }
    }
  },

  async mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
    await this.getWarehouses();
  },

  methods: {
    async getWarehouses() {
      await axios.get(route('get.article-warehouses')).then(response => {
        this.warehouses = response.data;
      }).catch(error => {
        console.log(error);
      });
    },

    async selectWarehouseOption(warehouseId) {
      if (document.getElementById('selectWarehouse') && warehouseId)
        document.getElementById('selectWarehouse').value = warehouseId;
    },

    onChangeQuantityDisposed(k, event) {
      let itemForm = this.form.selectedItems[k];
      if (event.target.value <= itemForm.can_disposition) {
        itemForm.quantity_disposed = event.target.value;
      }
      else {
        itemForm.quantity_disposed = itemForm.can_disposition;
        this.selectedItems[k].quantity_disposed = itemForm.can_disposition;
      }

    },

    async onWarehouseChange(event) {
      let newWarehouse = this.availableWarehouses.find(item => item.id === parseInt(event.target.value));
      this.form = useForm({
        selectedItems: this.selectedItems,
        oldWarehouse: this.selectedItems.warehouse,
        newWarehouse: newWarehouse,
        document_number: await this.generateDocumentNumber(
            null,
            newWarehouse,
            this.selectedItems.order_lists_item_id,
            this.selectedItems.quote_request_item_id || false
        ),
        comment: '',
      });
    },

    formatDate(date) {
      if (!date)
        return '';

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },

    async generateDocumentNumber(oldWarehouse, newWarehouse, order_list_item_id, from_quote_request = false) {
      const response = await axios.get(
          route('warehouse-movements.generate-document-number', {
            order_list_item_id: order_list_item_id,
            oldWarehouse: oldWarehouse,
            newWarehouse: newWarehouse,
            fromQuoteRequest: this.checkWarehouses ? from_quote_request : false
          })
      );
      return response.data.number; // Zwracanie numeru dokumentu
    },

    async saveMovement() {
      // usuń zbędne dane
      this.form.selectedItems.forEach(item => {
        delete item.project;
      });

      let response = await axios.post(route('warehouse-movements.store', {'data': this.form}));

      if (response.status === 200) {
        this.$emit('added-success', response.data);
      } else {
        this.$emit('added-error', response.data);
      }

      this.$emit('update-grid', true);
      this.form.processing = false;

      this.hide();
    },

    hasError(field) {
      return this.errors && this.errors[field];
    },

    getErrorMessage(field) {
      if (!this.errors[field]) {
        return '';
      }

      if (/^Pole .*? jest wymagane\.$/.test(this.errors[field])) {
        return 'To pole jest wymagane.';
      }

      return 'To pole ma niepoprawną wartość';
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}
</script>


<style scoped>
.widthFirstTd {
  width: 250px;
}

.order-list-table:not(:first-child) {
  border-top: 2px solid #222;
}
</style>