<template>
  <div v-if="isLoading">
    <div class="position-absolute w-100 h-100 text-center" style="z-index: 9">
      <div class="spinner-border text-danger me-2 align-self-center loader-lg" style="top:20%"></div>
    </div>
  </div>

  <div :style="{ opacity: isLoading ? '0.4' : '1' }">
    <ejs-grid id="tasksGrid" ref="gridTasks" :key="taskGridKey" gridLines="Both" height='101%' class="top-margin"
              :allowTextWrap='true'
              :keyPressed="onKeyDown"
              :allowExcelExport='true'
              :dataSource="tasksData"
              :editSettings="editSettings"
              :allowPaging="false"
              :pageSettings='pageSettings'
              :allowSorting='true'
              :allowFiltering='true'
              :filterSettings="filterOptions"
              :toolbar='toolbar'
              :allowReordering='true'
              :allowResizing='true'
              :showColumnChooser='true'
              :selectionSettings='selectionOptions'
              :allowSelection='true'
              :enablePersistence='true'
              :created="created"
              :beforeBatchSave="beforeBatchSave"
              :cellSave="onCellSave"
              :toolbarClick="toolbarClickE"
              :batchCancel="onCancelAction"
              :dataBound="dataBound">
      <e-columns>
        <e-column field='id' headerText='ID' width="85" :allowEditing="false" isPrimaryKey="true"></e-column>
        <e-column field="after_receipt" headerText="Pkt. otwarte z odbioru" type="string" editType='dropdownedit'
                  :edit="afterReceiptParams"></e-column>
        <e-column field="arrangement_id" headerText="ustalenie ID" type="number" :visible="false">
        </e-column>
        <e-column field="arrangement_description" headerText="Przypisany do ustalenia" type="string"
                  :allowEditing="false">
        </e-column>
        <e-column field="team" :edit="editParams" :valueAccessor="valueAccessor" headerText="Zespół" type="string"
                  :disableHtmlEncode="false"></e-column>
        <e-column field="task" :edit="editParams" :valueAccessor="valueAccessor" headerText="Zadanie"
                  :disableHtmlEncode="false" autoFillWidth></e-column>
        <e-column field="person_email" headerText="Osoba" type="string" :edit="personParams"></e-column>
        <e-column field="date" headerText="Data" type="date" editType='datepickeredit' :edit="editDateParams"
                  format='dd.MM.yyyy'></e-column>
        <e-column field="comments" :edit="editParams" :valueAccessor="valueAccessor" headerText="Uwagi" type="string"
                  autoFillWidth :disableHtmlEncode="false"></e-column>
        <e-column field="status" headerText="Status" type="string" editType='dropdownedit'
                  :edit="statusParams"></e-column>
        <e-column field="created_by_name" headerText="Stworzył" :allowEditing="false" type="string"></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</template>

<script>
import {MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {TextBox} from '@syncfusion/ej2-inputs';
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit,
  ExcelExport,
  Filter,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-vue-grids';
import {usePermission} from "@/composables/resources/js/composables/permissions.js";
import {Query} from "@syncfusion/ej2-data";

const {hasPermission} = usePermission();

export default {
  components: {
    hasPermission,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },
  emits: ['updateTasks'],
  props: {
    projectId: Number,
    notDoneOnly: Boolean,
    assignedToArrangement: Number,
    usersCanChange: Array,
    usersEmails: Object
  },

  mounted() {
    this.getTasks();
  },
  unmounted() {
    this.tasksData = null;
  },

  watch: {
    assignedToArrangement(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getTasks();
      }
    },
  },

  data() {
    let ddElem;
    let multiSelectObj;
    let personEmails = this.usersEmails;

    function createPersonFn() {
      ddElem = document.createElement('input');
      return ddElem;
    }

    function readPersonFn() {
      return multiSelectObj.value.join(",");
    }

    function destroyPersonFn() {
      multiSelectObj.destroy();
    }

    async function writePersonFn(args) {
      let multiSelectVal = args.rowData[args.column.field]
          ? args.rowData[args.column.field].split(",")
          : [];
      multiSelectObj = new MultiSelect({
        value: multiSelectVal,
        dataSource: personEmails,
        fields: {value: "person", text: "person"},
        floatLabelType: "Never",
        mode: "Box",
        allowCustomValue: true, // Pozwala na wpisywanie niestandardowych wartości
        customValueSpecifier: function (args) {
          // Dodaj niestandardowe wartości do listy
          personEmails.push({person: args.text});
          args.cancel = false; // Pozwala dodać niestandardową wartość
        },
      });
      multiSelectObj.appendTo(ddElem);
    }

    let textEditor;
    let elemContent;

    return {
      getPersonParamsInterval: null,
      tasksData: null,
      isLoading: false,
      taskGridKey: 0,
      pageSettings: {pageSize: 15},
      filterOptions: {type: "Excel"},
      toolbar: this.getToolbar(),
      riskRules: {required: true},
      editSettings: this.getEditSettings(),
      selectionOptions: {type: 'Multiple', cellSelectionMode: 'Box', mode: 'Cell'},
      personParams: {
        create: createPersonFn,
        destroy: destroyPersonFn,
        read: readPersonFn,
        write: writePersonFn
      },
      editDateParams: {
        params: {
          weekNumber: true,
          format: 'dd.MM.yyyy',
        }
      },
      editParams: {
        create: () => {
          elemContent = document.createElement("textarea");
          return elemContent;
        },
        read: () => {
          return textEditor.value;
        },
        destroy: () => {
          textEditor.destroy();
        },
        write: (args) => {
          textEditor = new TextBox({
            multiline: true,
            value: args.rowData[args.column.field],
            floatLabelType: "Auto",
          });
          textEditor.appendTo(elemContent);
        },
      },
      statusParams: this.getStatusParams(),
      afterReceiptParams: this.getAfterReceiptParams(),
    };
  },
  provide: {
    grid: [Page, Edit, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport]
  },

  methods: {
    created(args) {
      let gridInstance = document.getElementById('tasksGrid').ej2_instances[0];
      gridInstance.keyConfigs.enter = "";
    },

    valueAccessor: function (field, data, column) {
      let value = data[field];
      if (value !== undefined && value !== null) {
        return value.split("\n").join("<br>");
      } else {
        return "";
      }
    },

    onCellSave(args) {
      if (args.previousValue !== args.value) {
        this.$makeDirty();
      }
    },

    onCancelAction() {
      this.$makeClean();
    },

    getToolbar() {
      if (this.userCanModify()) {
        return [
          "Add",
          {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
          "Update",
          "Cancel",
          {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
          {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
          "ColumnChooser",
        ];
      }
      return [
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'}
      ];
    },

    toolbarClickE(args) {
      // Obsługa przycisku delete
      if (args.item.id === 'delete_btn') {
        const selectedIndexes = this.$refs.gridTasks.ej2Instances.getSelectedRowCellIndexes();

        if (selectedIndexes.length > 0) {
          const selectedRecords = selectedIndexes.map(index => this.$refs.gridTasks.ej2Instances.getRowByIndex(index.rowIndex));

          selectedRecords.forEach(record => {
            const data = this.$refs.gridTasks.ej2Instances.getCurrentViewRecords()[record.rowIndex];
            if (data && data.id) {
              // Usuwanie zaznaczonego rekordu
              this.$refs.gridTasks.ej2Instances.deleteRecord('id', data);
            }
          });
        }
      }

      if (args.item.id === 'excelexport') {
        this.$refs.gridTasks.excelExport();
      }
    },

    dataBound() {
      let gridInstance = this.$refs.gridTasks;
      gridInstance.hideScroll();

      let rows = gridInstance.getRows();
      for (let i = 0; i < rows.length; i++) {
        const data = gridInstance.getCurrentViewRecords()[i];
        if (data.after_receipt === 'Tak') {
          rows[i].classList.add('after-receipt');
        }
      }
    },

    getTasks() {
      this.isLoading = true;
      axios.get(route('projects.get-tasks-for-project', {
        projectId: this.projectId,
        notDoneOnly: this.notDoneOnly,
        assignedToArrangement: this.assignedToArrangement
      })).then((response) => {
        this.tasksData = response.data;
      }).finally(() => {
        this.isLoading = false;
      })
    },

    beforeBatchSave(args) {
      this.$makeClean();

      // przypisać do ustalenia- czyli jeśli jest z poziomu dodatkowych ustaleń do dodać arrangement_id,
      // jeśli nie to ustawić na null, żeby błąd nie wywalał
      args.batchChanges.addedRecords.forEach((ob, i) => {
        ob.arrangement_id = this.assignedToArrangement || null;
      });

      args.batchChanges.changedRecords.forEach((ob, i) => {
        ob.arrangement_id = this.assignedToArrangement || null;
      })

      try {
        this.$inertia.post(route('projects.show.tasks.updateTask', {project: this.projectId}),
            {
              updated: args.batchChanges.changedRecords,
              added: args.batchChanges.addedRecords,
              deleted: args.batchChanges.deletedRecords,
            },
            {
              preserveState: true,
              preserveScroll: true,
              onSuccess: async () => {

                await this.getTasks();

                this.successMsg('Dane zostały zapisane!');
                this.$makeClean();
              },

              onError: (errors) => {
                console.error('Błąd podczas zapisywania danych:', errors);
                this.errorMsg('Błąd podczas zapisywania danych.');
              },
            }
        );
      } catch (error) {
        console.log(error);
        this.$makeDirty();
        console.error('Błąd podczas zapisywania danych:', error);
        this.errorMsg('Błąd podczas zapisywania danych.');
      }
    },

    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
      };
    },

    getStatusParams() {
      const params = {
        dataSource: [
          {status: ''},
          {status: 'anulowano'},
          {status: 'częściowo'},
          {status: 'w trakcie'},
          {status: 'zrobiono'}
        ],
        query: new Query(),
        fields: {value: 'status', text: 'status'},
        allowFiltering: false,
      };
      return {params};
    },

    getAfterReceiptParams() {
      return {
        params: {
          dataSource: [
            {after_receipt: 'Nie'},
            {after_receipt: 'Tak'},
          ],
          fields: {text: "after_receipt", value: "after_receipt"},
        },
      };
    },

    userCanModify() {
      let rights = 0;
      if (this.usersCanChange.length > 0) {
        rights = this.usersCanChange.find(email => email === this.$page.props.user.email);
      }

      return rights !== 0 && rights !== undefined;
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        if (this.userCanModify) {
          // zapisz
          let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
          let buttons = toolbar.querySelectorAll('.e-toolbar-item');
          let updateButton = buttons[2].querySelector('button');
          if (updateButton) {
            updateButton.click();
          }
        }
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },
  }
}
</script>

<style>
.after-receipt {
  background-color: #faecb5 !important;
}

#tasksGrid_excelexport {
  float: right;
}

[id$="_excelexport"] {
  float: right;
}
</style>
